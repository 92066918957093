.button-row {
  margin: 20px 10%;
  display: flex;
  justify-content: space-between;

  .cv-btn-x2 {
    background-color: green;
    font-size: 18px;
    font-weight: 400;
    min-height: 50px;
    border-radius: 50px;
    width: 47%;
  }

  .cv-btn-x1 {
    background-color: green;
    font-weight: 400;
    font-size: 18px;
    min-height: 50px;
    border-radius: 50px;
    flex: 1;
  }
}

.img-button {
  height: 30px;
  padding-right: 10px;
}
