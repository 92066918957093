.register-card {
  display: flex;
  margin: 15px 10%;
  padding: 10px;
  background-color: transparent;
  border: 1px solid darkgrey;
  border-radius: 4px;

  .register-col1 {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 45px;

    .register-img {
      height: 40px;
    }
  }

  .register-col2 {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 10px;

    .rowBig {
      font-size: 18px;
      padding-bottom: 5px;
    }
    .rowSmall {
      line-height: 20px;
      font-size: 14px;
    }
  }
}
