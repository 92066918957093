/* You can add global styles to this file, and also import other style files */
@import "_button";
@import "register-card";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-menu-panel {
  min-height: 10px !important;
}
